import Chart from 'chart.js/auto'
import axios from "axios";

//import data from 'url:../stats.json';

async function init() {

    const stats = await axios.get("stats.json", {
        headers: {
            "Accept": "application/json",
        }
    });

    console.log(stats);
    const entries = stats.data.entries;

    new Chart(
        document.getElementById('chart'),
        {
            type: 'line',
            data: {
                labels: entries.map(entry => entry.timestamp),
                datasets: [
                    { label: 'Total', data: entries.map(entry => entry.total) },
                    { label: 'Errors (Files)', data: entries.map(entry => entry.errors) },
                    { label: 'Errors', data: entries.map(entry => entry.total_errors) },
                    { label: 'Warnings (Files)', data: entries.map(entry => entry.warnings) },
                    { label: 'Warnings', data: entries.map(entry => entry.total_warnings) }
                ]
            }
        }
    );

}

const _ = init();
